//
//
//
//
//
//
//

import {DayPilot, DayPilotScheduler} from 'daypilot-pro-vue'

export default {
  name: "Test",
  components: {
    DayPilotScheduler
  },
  data: function () {
    return {
      config: {
        timeHeaders: [
          {groupBy: "Month"},
          {groupBy: "Day", format: "d"}
        ],
        scale: "Day",
        startDate: DayPilot.Date.today().firstDayOfYear(),
        days: DayPilot.Date.today().daysInYear(),
        resources: [
          {name: "Resource 1", id: "R1"},
          {name: "Resource 2", id: "R2"},
          {name: "Resource 3", id: "R3"}
        ],
        events: [
          {
            id: 1,
            text: "Event 1",
            start: DayPilot.Date.today().addDays(2),
            end: DayPilot.Date.today().addDays(5),
            resource: "R2"
          }
        ],
        scrollTo: DayPilot.Date.today(),
        onBeforeCellRender: function (args) {
          console.log(1);
          // eslint-disable-next-line

          if (args.cell.start.getDayOfYear()%2 === 0) {
            args.cell.backColor = "#f5f5f5";
          }


        },
      },
    }
  },
  methods: {
    addEvent() {
      this.config.events.push(
        {
          id: 2,
          text: "Event 2",
          start: DayPilot.Date.today().addDays(6),
          end: DayPilot.Date.today().addDays(7),
          resource: "R3"
        }
      );
      console.log(this.config.events);
    }
  },
  computed: {
    // DayPilot.Scheduler object - https://api.daypilot.org/daypilot-scheduler-class/
    scheduler: function () {
      return this.$refs.scheduler.control;
    }
  },
  mounted: function () {
    this.scheduler.message("Welcome!");
  }
}
